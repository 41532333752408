'use client'

import ToggleIntercom from 'components/molecules/ToggleIntercom'
import { ReactElement, ReactNode } from 'react'

interface IIntercomButtonProps {
  children: ReactNode
}

/**
 * Custom component to render an Intercom opening button for given markdown/
 * @param {IIntercomButtonProps} props - The component properties.
 * @returns {ReactElement} The rendered component for a detected intercom button inside the markdown.
 */
export default function IntercomButton(
  props: IIntercomButtonProps
): ReactElement {
  return (
    <span
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
      }}
    >
      <ToggleIntercom>
        <button className="text-enpal-sky-blue hover:underline">
          {props.children}
        </button>
      </ToggleIntercom>
    </span>
  )
}
