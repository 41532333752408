'use client'

import Markdown from 'react-markdown'

import { ReactElement } from 'react'
import IntercomButton from './IntercomButton'

interface IMarkdownRendererProps {
  // The markdown string.
  md: string
}

/**
 * Component to convert a given markdown string into a react element.
 * @param {IMarkdownRendererProps} props - The component properties.
 * @returns {ReactElement} The markdown content rendered as react element.
 */
export default function MarkdownRenderer(
  props: IMarkdownRendererProps
): ReactElement {
  return (
    <Markdown
      skipHtml
      unwrapDisallowed
      allowedElements={['a']}
      components={{
        a(props) {
          const { children, node } = props
          const href = (node?.properties.href ?? '') as string
          if (!href) {
            return <>{children}</>
          }
          return href.startsWith('intercom') ? (
            <IntercomButton>{children}</IntercomButton>
          ) : (
            <a
              onClick={(e) => {
                e.stopPropagation()
              }}
              className="text-enpal-sky-blue hover:underline"
              href={href}
              target="_blank"
            >
              {children}
            </a>
          )
        },
      }}
    >
      {props.md}
    </Markdown>
  )
}
