'use client'

import { useIntercom } from 'hooks/useIntercom'
import React from 'react'

export default function ToggleIntercom({
  children,
}: {
  children: React.ReactNode
}) {
  const { toggleIntercom } = useIntercom()
  const button = React.Children.only(children) as React.ReactElement

  if (button.type !== 'button') {
    throw new Error('Child must be a button')
  }

  return <>{React.cloneElement(button, { onClick: toggleIntercom })}</>
}
